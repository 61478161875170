//======================================================================
// variable
//======================================================================
// imgディレクトリまでのパス
$img_dir: '../img';

// コンテンツ幅
$content_width: 100%;

$max_content_width: 1025px;

// サイドメニュー幅
$side_menu_width: 220px;

// SP時の左右の余白（片方）
$sp_side_padding: 15px;

//=================================
// breakpoint
//=================================
// 下記2つの変数の間がタブレット表示
// タブレット表示なしの場合は2つの変数の値を「1px差」で設定すること

// $pc_bp以上がPC表示
$pc_bp: 1025px;

// $sp_bp以下がSP表示
$sp_bp: 767px;

//=================================
// font
//=================================
$font_1: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', '游ゴシック', YuGothic, sans-serif;
$font_2: 'Roboto', 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', '游ゴシック', YuGothic, sans-serif;

//=================================
// color
//=================================
$black: #242424;

$white: #fff;

$gray_1: #e6e6e6;
$gray_2: #fafafa;
$gray_3: #f2f2f2;
$gray_4: #ccc;
$gray_5: #11111114;
$gray_6: #d9d9d9;
$gray_7: #989898;

$bluegreen_1: #00a29a;
$bluegreen_2: #8ebe97;
$bluegreen_3: #008983;
$bluegreen_4: #e4f2f1;
$bluegreen_5: #def3f2;

$pink_1: #e73b79;
$pink_2: #f11a89;
$pink_3: #be2e62;

$red_1: #e73b3b;

$blue_1: #006ab7;
$blue_2: #5496c6;

$yellow_1: #fff67c;

$orange_1: #b72A00;
$orange_2: #e84a4a;
$orange_3: #f86000;

$color_1: #242424;
$color_2: #343434;
$color_3: #DDD;
$color_4: #D8523A;
$color_5: #707070;
$color_6: #FF4B4B;
$color_7: #FFDEDE;
$color_8: #C1C1C1;
$color_9: #D3F7FF;
$color_10: #FFFAC4;

//=================================
// CSS Transition Easing
//=================================
// Default
$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in: cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out: cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out: cubic-bezier(0.420, 0.000, 0.580, 1.000);
// In
$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045);
// Out
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
// In Out
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);
