/* ======================================================================
 component
====================================================================== */

/* =================================
 title
================================= */
.c_ttl_primary {
  line-height: 130%;
  font-weight: 900;
  text-align: center;
  font-size: 40px;
  &._flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_img {
    object-fit: contain;
  }
  @include sp {
    font-size: 30px;
    &_img {
      width: 34px;
      margin: 0 0 0 4px;
    }
  }
}

.c_ttl_secondary {
  display: flex;
  align-items: center;
  padding: 0 0 15px 0;
  line-height: 40px;
  font-weight: 700;
  font-size: 30px;
  background-image: url(#{$img_dir}/common/dot.svg);
  background-repeat: repeat-x;
  background-position-y: 100%;
  @include sp {
    line-height: 32px;
    font-size: 24px;
  }
  &_icon {
    width: 80px;
    height: 40px;
    margin: 0 5px 0 0;
    object-fit: contain;
    object-position: center center;
  }
  &_green {
    background-image: url(#{$img_dir}/common/dot-1.svg);
  }
}

.c_ttl_tertiary {
  display: flex;
  align-items: center;
  padding: 0 0 14px;
  margin: 0 0 16px 0;
  font-size: 30px;
  font-weight: 700;
  background-image: url(#{$img_dir}/common/dot-1.svg);
  background-repeat: repeat-x;
  background-position-y: 100%;
  background-size: auto 3px;
  &_circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    margin: 0 8px 0 0;
    border-radius: 9999px;
    background: $bluegreen_1;
    line-height: 130%;
    font-weight: 500;
    font-size: 14px;
    color: $white;
  }
  &_circle_num {
    font-size: 20px;
    font-family: $font_2;
    font-weight: 500;
    color: $yellow_1;
  }
  @include sp {
    &_ttl {
      margin: 20px 0 14px 0;
      line-height: 130%;
      font-size: 24px;
    }
  }
}

// article title
.c_article_ttl_primary_box {
  display: flex;
  align-items: flex-start;
  .c_article_ttl_primary {
    line-height: 120%;
    font-weight: 900;
    font-size: 30px;
    &_img {
      margin: 0 10px 0 0;
    }
  }
}

.c_article_ttl_secondary {
  display: flex;
  margin: 40px 0 30px 0;
  line-height: 120%;
  font-weight: 700;
  font-size: 26px;
  &:before {
    content: "";
    flex-shrink: 0;
    width: 5px;
    height: 30px;
    margin: 0 10px 0 0;
    border-radius: 9999px;
    background: $bluegreen_1;
  }
}

.c_article_ttl_tertiary {
  display: flex;
  margin: 50px 0 26px 0;
  padding: 15px 20px;
  border-radius: 6px;
  background: $gray_2;
  line-height: 120%;
  font-weight: 700;
  font-size: 22px;
  &:before {
    content: "";
    flex-shrink: 0;
    width: 20px;
    height: 4px;
    margin: 12px 10px 0 0;
    background: $bluegreen_1;
  }
}

.c_article_ttl_quaternary {
  margin: 50px 0 26px 0;
  padding: 0 0 15px 0;
  line-height: 30px;
  font-weight: 700;
  font-size: 20px;
  background-image: linear-gradient(to right, $bluegreen_1 150px, $gray_4 150px, $gray_4 100%);
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: 0 100%;
  @include sp {
    background-image: linear-gradient(to right, $bluegreen_1 80px, $gray_4 80px, $gray_4 100%);
  }
}

.c_article_ttl_quinary {
  display: flex;
  margin: 50px 0 20px 0;
  line-height: 120%;
  font-weight: 700;
  font-size: 18px;
  &:before {
    content: "";
    flex-shrink: 0;
    width: 20px;
    height: 4px;
    margin: 8px 10px 0 0;
    background: $bluegreen_1;
  }
}

/* =================================
  text
================================= */
.c_txt_point {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 45px;
  color: $pink_1;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  &:after,
  &:before {
    content: "";
    height: 1px;
    width: 26px;
    background: $pink_1;
  }
  &:before {
    margin: 0 8px 0 0;
    transform: rotate(50deg);
  }
  &:after {
    margin: 0 0px -1px 2px;
    transform: rotate(-50deg);
  }
}

.c_txt_link_1 {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  line-height: 1.7;
  font-size: 18px;
  &:link,
  &:visited {
    color: $bluegreen_1;
  }
  @include pc_tab {
    &:hover {
      text-decoration: underline;
      color: $bluegreen_1;
    }
  }
  &:before {
    content: "";
    width: 10px;
    margin: 0 3px 0 0;
    border-left: solid 8px $bluegreen_1;
    border-top: solid 6px transparent;
    border-bottom: solid 6px transparent;
  }
}

.c_txt_link_2 {
  @extend .c_txt_link_1;
  &:after {
    content: "";
    width: 12px;
    height: 12px;
    margin: auto 0 7px 5px;
    background: url(#{$img_dir}/common/link.svg) no-repeat;
    background-size: contain;
  }
}

.c_txt_dot {
  display: flex;
  align-items: flex-start;
  margin: 30px 0 15px 0;
  line-height: 30px;
  font-weight: 700;
  font-size: 20px;
  &:before {
    content: "";
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    margin: 11px 10px 0 0;
    border-radius: 9999px;
    background: $bluegreen_1;
  }
}

.c_txt_caption {
  display: flex;
  font-size: 16px;
  &:before {
    content: "※";
    margin: 0 5px 0 0;
    font-size: 16px;
  }
}

/* =================================
 buttons
================================= */
.c_gotop {
  position: fixed;
  z-index: 10;
  right: 10px;
  bottom: 10px;
  width: 60px;
  height: 60px;
  object-fit: contain;
  &._top {
    @include sp {
      bottom: 80px;
    }
    @include tab {
      bottom: 90px;
    }
  }
  a {
    display: block;
  }
}

.c_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 9999px;
  font-weight: 700;
  color: $white;
  transition: 0.3s;
  &:after {
    content: "";
    width: 10px;
    margin: 0 -8px 0 11px;
    border-left: solid 8px $white;
    border-top: solid 6px transparent;
    border-bottom: solid 6px transparent;
    transition: 0.3s;
  }
  &:link,
  &:visited {
    color: $white;
  }
  @include pc_tab {
    &:hover {
      color: $white;
    }
  }
}

.c_btn_primary {
  @extend .c_btn;
  max-width: 350px;
  height: 70px;
  box-shadow: 0 3px 0 0 $pink_3;
  background: $pink_1;
  font-size: 26px;
  @include pc_tab {
    &:hover {
      transform: translateY(3px);
      box-shadow: 0 0 0 0 $pink_3;
      opacity: 0.5;
      &:after {
        transform: scale(0.7);
      }
    }
  }
  &._sm {
    height: 60px;
    font-size: 18px;
  }
  &._xs {
    height: 60px;
    font-size: 16px;
    @include sp {
      font-size: 16px;
    }
  }
  @include sp {
    height: 60px;
    font-size: 18px;
  }
}

.c_btn_secondary {
  @extend .c_btn;
  width: fit-content;
  min-height: 59px;
  padding: 12.5px 32px;
  border: solid 2px $bluegreen_1;
  line-height: 170%;
  text-align: center;
  font-size: 18px;
  background: $bluegreen_1;
  @include pc_tab {
    &:hover {
      background: $yellow_1;
      color: $black;
      &:after {
        border-left: solid 8px $black;
      }
    }
  }
  &._sm {
    width: 100%;
    min-height: 45px;
    padding: 3.5px 25px;
  }
  &._px40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include sp {
    width: 320px;
    max-width: 100%;
    &._sm {
      min-width: unset;
      min-height: 30px;
      padding: 0 25px;
      font-size: 14px;
    }
  }
}

.c_btn_tertiary {
  @extend .c_btn;
  max-width: 345px;
  height: 59px;
  padding: 0 32px;
  border: solid 2px $pink_1;
  background: $yellow_1;
  font-size: 18px;
  color: $black;
  &:after {
    border-left: solid 8px $black;
  }
  &:link,
  &:visited {
    color: $black;
  }
  @include pc_tab {
    &:hover {
      color: $white;
      background: $pink_1;
      color: $white;
      &:after {
        border-left: solid 8px $white;
      }
      .u_txt_pink {
        color: $white;
      }
    }
  }
}

.c_btn_quaternary {
  @extend .c_btn;
  max-width: 350px;
  height: 60px;
  box-shadow: 0 3px 0 0 $bluegreen_3;
  background: $bluegreen_1;
  font-size: 18px;
  &_img {
    margin: 0 10px -2px 0;
  }
  @include pc_tab {
    &:hover {
      transform: translateY(3px);
      box-shadow: 0 0 0 0 $bluegreen_3;
      opacity: 0.5;
    }
  }
  &:after {
    content: none;
  }
}

.c_btn_quinary {
  @extend .c_btn;
  width: 320px;
  max-width: 100%;
  min-height: 59px;
  padding: 12.5px 0;
  line-height: 170%;
  text-align: center;
  font-size: 18px;
  background: $black;
  &:after {
    content: none;
  }
  &:before {
    content: "";
    width: 10px;
    margin: 0 11px 0 -8px;
    border-right: solid 8px $white;
    border-top: solid 6px transparent;
    border-bottom: solid 6px transparent;
    transition: 0.3s;
  }
  @include pc_tab {
    &:hover {
      opacity: 0.7;
      &:after {
        border-left: solid 8px $black;
      }
    }
  }
  @include sp {
    min-width: 320px;
  }
}

.c_btn_senary {
  @extend .c_btn;
  position: relative;
  width: 275px;
  max-width: 100%;
  min-height: 59px;
  padding: 0 20px;
  line-height: 170%;
  text-align: center;
  font-size: 18px;
  background: $black;
  &:hover {
    color: $white;
  }
  &:before {
    content: "";
    order: 3;
    right: 0;
    width: 9px;
    height: 9px;
    margin: 0 -10px 0 10px;
    border: none;
    border-bottom: solid 1px $white;
    border-right: solid 1px $white;
    transform: rotate(45deg) translate(-10%, -10%);
  }
  &:after {
    content: "を開く";
    order: 2;
    width: fit-content;
    margin: 0;
    border: none;
  }
  @include pc_tab {
    &:hover {
      opacity: 0.7;
    }
  }
  &._close {
    &:before {
      transform: rotate(-135deg) translate(-30%, -30%);
    }
    &:after {
      content: "を閉じる";
    }
  }
  @include sp {
    min-width: 320px;
  }
}

.c_btn_septenary {
  @extend .c_btn;
  max-width: 350px;
  height: 70px;
  box-shadow: 0 3px 0 0 $pink_1;
  background: $white;
  font-size: 26px;
  color: $pink_1;
  border: 2px solid $pink_1;
  &:after {
    border-left: solid 8px $pink_1;
  }
  &:link,
  &:visited {
    color: $pink_1;
  }
  @include pc_tab {
    &:hover {
      transform: translateY(3px);
      box-shadow: 0 0 0 0 $pink_1;
      opacity: 0.5;
      background: $pink_1;
      color: $white;
      &:after {
        transform: scale(0.7);
        border-left: solid 8px $white;
      }
    }
  }
  &._sm {
    height: 60px;
    font-size: 18px;
  }
  @include sp {
    height: 60px;
    font-size: 18px;
  }
}

.c_btn_octonary {
  @extend .c_btn;
  max-width: 345px;
  height: 59px;
  padding: 0 32px;
  background: $pink_1;
  color: $white;
  font-size: 18px;
  border: solid 2px $pink_1;
  &:after {
    border-left: solid 8px $white;
  }
  &:link,
  &:visited {
    color: $white;
  }
  @include pc_tab {
    &:hover {
      background: $yellow_1;
      color: $black;
      &:after {
        border-left: solid 8px $pink_1;
      }
      .u_txt_pink {
        color: $white;
      }
    }
  }
}

.c_btn_icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 23px 0;
  border-radius: 9999px;
  background: $gray_2;
  &_item {
    margin: 0 15px;
  }
  &_link {
    display: block;
    img {
      transition: 0.3s;
    }
    @include pc_tab {
      &:hover {
        img {
          opacity: 0.6;
        }
      }
    }
  }

  @include sp {
    padding: 15px 0;
  }
}

/* =================================
  category
================================= */
.c_cat_1 {
  display: inline-block;
  width: fit-content;
  padding: 3px 12px 4px 12px;
  border-radius: 9999px;
  font-weight: 300;
  font-size: 16px;
  color: $white;
  background: $bluegreen_1;
  @include sp {
    font-size: 14px;
  }
}

.c_cat {
  display: inline-block;
  width: fit-content;
  padding: 1px 38px 3px 38px;
  border-radius: 9999px;
  background: $white;
  line-height: 16px;
  font-size: 14px;
  transition: 0.3s;
  &._lg {
    padding: 7px 36px 8px 36px;
    box-shadow: 2px 3px 1px 0px rgba(0, 0, 0, 0.082);
    line-height: 30px;
    font-weight: 700;
    font-size: 18px;
  }
  @include pc_tab {
    &:link:hover {
      opacity: 0.6;
    }
  }
  @include sp {
    padding: 1px 25px 2px 25px;
  }
}

.c_cat_2 {
  @extend .c_cat;
  border: solid 1px $pink_1;
  &,
  &:link,
  &:visited {
    color: $pink_1;
  }
  @include pc_tab {
    &:hover {
      color: $pink_1;
    }
  }
}

.c_cat_3 {
  @extend .c_cat;
  border: solid 1px $blue_1;
  &,
  &:link,
  &:visited {
    color: $blue_1;
  }
  @include pc_tab {
    &:hover {
      color: $blue_1;
    }
  }
}

.c_cat_4 {
  @extend .c_cat;
  border: solid 1px $bluegreen_1;
  &,
  &:link,
  &:visited {
    color: $bluegreen_1;
  }
  @include pc_tab {
    &:hover {
      color: $bluegreen_1;
    }
  }
}

.c_cat_5 {
  @extend .c_cat;
  border: solid 1px $gray_4;
  &,
  &:link,
  &:visited {
    color: $black;
    background: $white;
  }
  &._lg {
    padding: 7px 27px 8px 27px;
    @include pc_tab {
      &:hover {
        opacity: 1;
        border: solid 1px $bluegreen_1;
        color: $white;
        background: $bluegreen_1;
      }
    }
  }
  &._active {
    color: $white;
    border: solid 1px $bluegreen_1;
    background: $bluegreen_1;
  }
}

.c_cat_6 {
  @extend .c_cat;
  border: solid 1px $orange_1;
  &,
  &:link,
  &:visited {
    color: $orange_1;
  }
  @include pc_tab {
    &:hover {
      color: $orange_1;
    }
  }
  &._lg {
    padding: 7px 27px 8px 27px;
  }
}

.c_cat_7 {
  display: inline-block;
  width: fit-content;
  padding: 4px 30.8px 4.5px 30.8px;
  border-radius: 9999px;
  font-weight: 900;
  font-size: 24px;
  color: $yellow_1;
  background: $bluegreen_1;
  @include sp {
    padding: 2.4px 16.2px 3px 16.2px;
    font-size: 11px;
  }
}

.c_cat_search {
  width: 100%;
  padding: 30px;
  border-radius: 30px;
  border: solid 1px $bluegreen_1;
  background: $gray_2;
  &_ttl {
    display: flex;
    align-items: flex-start;
    margin: 30px 0 15px 0;
    line-height: 30px;
    font-weight: 700;
    font-size: 20px;
    &:nth-of-type(1) {
      margin-top: 0;
    }
    &:before {
      content: "";
      flex-shrink: 0;
      width: 10px;
      height: 10px;
      margin: 11px 10px 0 0;
      border-radius: 9999px;
      background: $bluegreen_1;
    }
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: -7px -5px;
  }
  &_item {
    margin: 7px 5px;
  }

  @include sp {
    padding: 30px 15px;
    border-radius: 10px;
    &_list_wrapper {
      overflow: hidden;
      border-radius: 10px;
      border: solid 1px $gray_4;
      box-shadow: 2px 3px 1px 0px rgba(0, 0, 0, 0.082);
      background: $white;
      transition: 0.3s;
    }
    &_select {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 30px;
        right: 15px;
        margin: 0 7px 0 -14px;
        width: 7px;
        height: 7px;
        border-bottom: solid 1px $black;
        border-right: solid 1px $black;
        transform: rotate(45deg);
        transition: 0.3s;
      }
      &._open {
        &:after {
          transform: rotate(225deg);
        }
      }
      &_inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 70px;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        cursor: pointer;
      }
    }
    &_list {
      display: none;
      flex-wrap: nowrap;
      flex-direction: column;
      margin: 0;
      padding: 0 10px;
      *[class^="c_cat"] {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height:70px;
        margin: 0 auto;
        border-radius: 0;
        border: none;
        box-shadow: none;
        transition: 0.3s;
      }
      & > *[class^="c_cat"]:first-child {
        cursor: pointer;
        @include pc_tab {
          &:hover {
            opacity: 0.6;
          }
          .c_cat_5._lg:hover {
            border: none;
            background: $white;
            color: $black;
          }
        }
      }
    }

    ._open .c_cat_search_list > *[class^="c_cat"]:first-child {
      opacity: 0.5;
      &:after {
        transform: rotate(-135deg);
      }
    }
    &_item {
      height: 60px;
      margin: 0;
      & *[class^="c_cat"] {
        border-top: solid 2px $gray_2;
      }
    }
  }
}

/* =================================
 icon
================================= */
.c_icon_1 {
  width: 49px;
  height: 40px;
  object-fit: contain;
}

.c_icon_2 {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

/* =================================
 table
================================= */
.c_table_1 {
  width: 100%;
  &_heading,
  &_data {
    padding: 20px;
    border: solid 1px $gray_4;
    line-height: 1.7;
    font-size: 16px;
  }

  $heading_width: 253px;
  &_heading {
    width: $heading_width;
    font-weight: 700;
    background: $bluegreen_5;
  }
  &_data {
    width: calc(100% - #{$heading_width});
  }

  @include sp {
    &_heading,
    &_data {
      display: block;
      width: 100%;
      border: none;
    }
    &_heading + &_heading,
    &_heading + &_data,
    &_data + &_heading,
    &_data + &_data {
      border-top: solid 1px $gray_4;
    }
    &_row {
      border: solid 1px $gray_4;
      & + & {
        border-top: none;
      }
    }
  }
}

.c_table_2 {
  width: 100%;
  &_heading,
  &_data {
    padding: 20px;
    border: solid 1px $gray_4;
    line-height: 1.7;
    font-size: 16px;
  }

  $heading_width: 253px;
  &_heading {
    width: $heading_width;
    font-weight: 700;
    background: $bluegreen_5;
  }
  &_data {
    width: calc((100% - #{$heading_width}) / 2);
  }

  @include sp {
    &_heading,
    &_data {
      display: block;
      width: 100%;
      border: none;
    }
    &_heading + &_heading,
    &_heading + &_data,
    &_data + &_heading,
    &_data + &_data {
      border-top: solid 1px $gray_4;
    }
    &_row {
      border: solid 1px $gray_4;
      & + & {
        border-top: none;
      }
    }
  }
}

.c_table_3 {
  width: 100%;
  &_heading,
  &_data {
    padding: 20px;
    border: solid 1px $gray_4;
    line-height: 1.7;
    font-size: 16px;
  }
  &_heading {
    width: 50%;
    font-weight: 700;
    background: $bluegreen_5;
  }
  &_data {
    width: 50%;
  }
}

.c_table_4 {
  width: 100%;
  &_row {
    display: flex;
    & + & {
      margin: 10px 0 0 0;
      padding: 10px 0 0 0;
    }
  }
  &_heading,
  &_data {
    line-height: 1.7;
    font-size: 18px;
  }
  &_heading {
    width: 215px;
    font-weight: 700;
  }
  &_data {
    width: calc(100% - 235px);
    margin: 0 0 0 20px;
  }
  &._border {
    .c_table_4_row + .c_table_4_row {
      border-top: solid 2px $gray_1;
    }
    .c_table_4_data {
      padding: 0 15px 0 0;
    }
    .c_table_4_heading {
      padding: 0 0 0 15px;
    }
  }

  @include sp {
    &_row {
      display: flex;
      flex-direction: column;
      & + & {
        margin: 8px 0 0 0;
        padding: 8px 0 0 0;
      }
    }
    &_heading {
      width: 100%;
    }
    &_data {
      width: 100%;
      margin: 5px 0 0 0;
    }
    &._border {
      .c_table_4_data,
      .c_table_4_heading {
        padding: 0;
      }
    }
  }
}

/* =================================
 list
================================= */
.c_list_dot {
  margin: 0 0 0 20px;
  line-height: 1.7;
  font-size: 18px;
  &_item {
    display: flex;
    align-items: flex-start;
    padding: 15px 0;
    &:nth-of-type(n + 2) {
      border-top: dotted 1px $gray_4;
    }
    &:before {
      content: "";
      flex-shrink: 0;
      width: 10px;
      height: 10px;
      margin: 11px 10px 0 0;
      border-radius: 9999px;
      background: $bluegreen_1;
    }
  }
}

.c_list_order {
  margin: 0 0 0 20px;
  line-height: 1.7;
  font-size: 18px;
  &_item {
    counter-increment: list;
    display: flex;
    align-items: flex-start;
    padding: 15px 0;
    &:before {
      content: "0" counter(list);
      justify-content: center;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: 34px;
      height: 34px;
      margin: 0 10px -15px 0;
      border-radius: 9999px;
      background: $bluegreen_1;
      white-space: nowrap;
      letter-spacing: 0.5px;
      font-family: $font_2;
      font-weight: 700;
      font-size: 14px;
      color: $yellow_1;
    }
    &:nth-of-type(n + 2) {
      border-top: dotted 1px $gray_4;
    }
    &:nth-of-type(n + 10):before {
      content: counter(list);
    }
  }

  @include sp {
    &_item {
      &:before {
        margin: 0 10px -15px 0;
      }
    }
  }
}

.c_list_3 {
}

/* =================================
 item
================================= */
// c_item_1
.c_item_1 {
}

// c_item_2
.c_item_2 {
}

// c_item_3
.c_item_3 {
}

/* =================================
 pager
================================= */
.c_pager_num {
  margin-top: 50px;
  @include sp {
    margin-top: 40px;
  }
}

.c_pager_prev_next {
}

/* =================================
 card list
================================= */
.c_card_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &_item {
    width: calc(100% / 3 - 20px);
    max-width: 315px;
    & + & {
      margin: 0 0 0 30px;
    }
  }
  @include sp {
    flex-direction: column;
    &_item {
      display: flex;
      justify-content: center;
      width: 100%;
      & + & {
        margin: 40px 0 0 0;
      }
    }
  }
}

.c_card_list_2 {
  display: flex;
  flex-wrap: wrap;
  margin: -13px -10px;
  &_item {
    width: calc(50% - 20px);
    margin: 13px 10px;
  }
  @include sp {
    flex-direction: column;
    margin: 0;
    &_item {
      width: 100%;
      margin: 0;
      & + & {
        margin: 15px 0 0 0;
      }
    }
  }
}

/* =================================
 card
================================= */
.c_card {
  display: flex;
  flex-direction: column;
  max-width: 315px;
  min-height: 425px;
  transition: 0.3s;
  @include pc_tab {
    &:hover {
      .c_card_img {
        opacity: 0.6;
      }
      .c_btn_secondary {
        background: $yellow_1;
        color: $black;
        &:after {
          border-left: solid 8px $black;
        }
      }
    }
  }
  &_ttl {
    margin: 18px 0 10px 0;
    font-size: 26px;
    font-weight: 700;
  }
  &_btn {
    margin: auto auto 0 auto;
  }
  &_img {
    width: 100%;
    transition: 0.3s;
  }
  @include sp {
    width: 100%;
    max-width: unset;
    min-height: unset;
    &_img {
      width: 157px;
      height: 105px;
    }
    &_ttl {
      margin: 0 0 0 15px;
      font-size: 24px;
    }
    &_flex {
      display: flex;
      align-items: center;
      margin: 0 0 15px 0;
    }
    &_btn {
      margin: 15px auto 0 auto;
    }
  }
}

.c_card_2 {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  min-height: 425px;
  transition: 0.3s;
  @include pc_tab {
    &:hover {
      .c_card_2_img {
        opacity: 0.6;
      }
      .c_btn_tertiary {
        color: $white;
        background: $pink_1;
        color: $white;
        &:after {
          border-left: solid 8px $white;
        }
        .u_txt_pink {
          color: $white;
        }
      }
    }
  }

  &_inner {
    position: relative;
    overflow: hidden;
    height: 395px;
    margin: 0 0 -35px 0;
    border-radius: 20px;
    &:before {
      content: "";
      position: absolute;
      width: 280px;
      height: 280px;
      border-radius: 9999px;
      background: $bluegreen_1;
      bottom: -40px;
      left: -32px;
      opacity: 0.8;
      transition: 0.3s;
    }
  }
  &_ttl {
    margin: 23px 0 10px 0;
    font-size: 26px;
    font-weight: 700;
  }
  &_btn {
    width: 100%;
    max-width: 280px;
    position: relative;
    margin: auto auto 0 auto;
  }
  &_img {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -1;
    margin: 0 0 -232px 0;
    object-fit: cover;
    object-position: center;
    transition: 0.3s;
  }
  &_txt {
    position: relative;
    max-width: 220px;
    margin: 0 0 0 20px;
    color: $white;
  }
  &_ttl {
    margin: 18px 0 3px 0;
    line-height: 150%;
    font-size: 36px;
  }
  @include sp {
    width: 100%;
    max-width: 345px;
    min-height: unset;
    border-radius: 10px;
    &_inner {
      height: 220px;
      border-radius: 10px;
      &:before {
        width: 254px;
        height: 254px;
        bottom: -15px;
        left: unset;
        right: -60px;
      }
    }
    &_img {
      margin: 0 0 -200px 0;
    }
    &_btn {
      margin: 5px auto 0 auto;
    }
    &_txt {
      margin: -15px 20px 0 auto;
      p {
        width: fit-content;
        margin: 0 -15px 0 auto;
        font-weight: 300;
      }
    }
    &_ttl {
      width: fit-content;
      margin: 30px 0 10px auto;
      line-height: 125%;
      text-align: center;
      font-size: 26px;
    }
  }
}

.c_card_3 {
  display: flex;
  flex-direction: column;
  max-width: 475px;
  height: 100%;
  padding: 30px 40px 20px 30px;
  &_left {
    display: flex;
    width: 100%;
  }
  &_right {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0 5px;
  }

  &_date {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    position: relative;
    width: 86px;
    height: 86px;
    padding: 10px;
    border-radius: 15px;
    border: solid 3px $bluegreen_1;
    background-image: linear-gradient(-45deg, transparent calc(50% - 1px), $black calc(50% - 1px), $black 50%, transparent calc(50%));
    background-size: 55px;
    background-repeat: no-repeat;
    background-position: center;
    &:after {
      content: url(#{$img_dir}/common/icon.svg);
      position: absolute;
      right: -15px;
      top: -12px;
      width: 37px;
      height: 30px;
      object-fit: contain;
    }
  }
  &_m,
  &_d {
    justify-self: center;
    align-self: center;
    font-family: $font_2;
    font-weight: 700;
    font-size: 22px;
  }
  &_m {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
  &_d {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
  &_cat {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 12px 0 0 0;
    padding: 5px;
    border-radius: 9999px;
    background: $yellow_1;
    font-size: 16px;
    &._lg {
      padding: 5px 5px;
      border-radius: 10px;
      text-align: center;
      font-size: 15px;
    }
  }
  &_ttlbox {
    width: 75%;
    margin: 0 0 0 20px;
  }
  &_ttl {
    line-height: 140%;
    font-weight: 700;
    font-size: 26px;
  }
  $tag_mg: 3px;
  &_tag_list {
    display: flex;
    flex-wrap: wrap;
    margin: 5px -#{$tag_mg} -#{$tag_mg} -#{$tag_mg};
    border-bottom: 1px solid #ccc;
    padding: 0 0 10px 0;
  }
  &_tag {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $tag_mg;
    padding: 5px 10px;
    border-radius: 9999px;
    background: $bluegreen_1;
    font-weight: 300;
    font-size: 16px;
    color: $white;
  }
  &_price {
    margin: 12.5px 0 0 0;
    text-align: right;
    font-weight: 700;
  }
  @include sp {
    max-width: unset;
    padding: 15px;
    border-radius: 10px;
    &_right {
      align-items: flex-start;
    }
    &_item {
      width: 100%;
    }
    &_date {
      width: 60px;
      height: 60px;
      padding: 5px;
      border-radius: 5px;
      border: solid 2px $bluegreen_1;
      background-size: 35px;
    }
    &_m,
    &_d {
      font-size: 16px;
    }
    &_cat {
      width: 60%;
      border-radius: 6px;
      text-align: center;
      line-height: 140%;
      font-size: 14px;
    }
    &_ttl {
      margin: 0 0 0 0;
      line-height: 160%;
      font-size: 18px;
    }
    &_tag_list {
      margin: 9px -#{$tag_mg} -#{$tag_mg} -#{$tag_mg};
    }
    &_tag {
      padding: 1.5px 10px 2.5px 10px;
      font-size: 14px;
    }
    &_price {
      margin: 10px 0 0 0;
    }
  }
}

.c_card_4 {
  display: flex;
  max-width: 1025px;
  & > *:nth-child(1) {
    margin: 0 40px 0 0;
  }
  &_left {
    flex-shrink: 0;
    width: 420px;
    max-width: 42%;
    height: 280px;
    object-fit: cover;
    border-radius: 20px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_ttl {
    display: flex;
    align-items: center;
    padding: 0 0 14px;
    margin: 0 0 16px 0;
    line-height: 40px;
    font-size: 30px;
    font-weight: 700;
    background-image: url(#{$img_dir}/common/dot-1.svg);
    background-repeat: repeat-x;
    background-position-y: calc(100% - 1px);
    background-size: auto 3px;
  }
  &_sub_ttl {
    margin: 0 0 12px 0;
    line-height: 34px;
    font-size: 26px;
    font-weight: 700;
  }
  &_circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    margin: 0 8px 0 0;
    border-radius: 9999px;
    background: $bluegreen_1;
    line-height: 130%;
    font-weight: 500;
    font-size: 14px;
    color: $white;
  }
  &_circle_num {
    font-size: 20px;
    font-family: $font_2;
    font-weight: 500;
    color: $yellow_1;
  }
  &._rev {
    flex-direction: row-reverse;
    .c_card_4 {
      &_left {
        margin: 0 0 0 40px;
      }
    }
  }

  @include sp {
    flex-direction: column;
    & > *:nth-child(1) {
      margin: 0;
    }
    &_left {
      width: 100%;
      max-width: 100%;
      height: 221px;
      border-radius: 10px;
    }
    &_ttl {
      margin: 20px 0 14px 0;
      line-height: 130%;
      font-size: 24px;
    }
    &_sub_ttl {
      line-height: 150%;
      font-size: 20px;
    }
    &._rev {
      flex-direction: column;
      .c_card_4 {
        &_left {
          margin: 0;
        }
      }
    }
  }
}
*:nth-of-type(even) > .c_card_4 {
  flex-direction: row-reverse;
  .c_card_4_left {
    margin: 0 0 0 40px;
  }
  @include sp {
    flex-direction: column;
    .c_card_4_left {
      margin: 0;
    }
  }
}

.c_card_5 {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  height: 132px;
  border-radius: 20px;
  border: solid 1px $gray_4;
  box-shadow: 2px 3px 1px 0px rgba(0, 0, 0, 0.082);
  transition: 0.3s;
  @include pc_tab {
    &:link:hover {
      opacity: 0.8;
    }
    &:hover {
      .c_btn_secondary {
        background: $yellow_1;
        color: $black;
        &:after {
          border-left: solid 8px $black;
        }
      }
    }
  }
  &_left {
    width: 40%;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
  }
  &_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 0 auto;
  }
  &_ttl {
    font-size: 26px;
    font-weight: 700;
  }
  &_btn {
    width: 84%;
    margin: 15px 0 0 0;
  }
  @include sp {
    border-radius: 10px;
    max-width: unset;
    height: 102px;
    &_left {
      width: 34.5%;
      border-radius: 10px 0 0 10px;
    }
    &_right {
      width: 65.5%;
      padding: 0 9px;
    }
    &_ttl {
      font-size: 24px;
    }
    &_btn {
      width: 100%;
      white-space: nowrap;
    }
  }
}

.c_card_6 {
  display: flex;
  width: 100%;
  max-width: 900px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  border: solid 1px $gray_4;
  box-shadow: 2px 3px 1px 0px rgba(0, 0, 0, 0.082);
  transition: 0.3s;
  &_left {
    flex-shrink: 0;
    width: 240px;
    height: auto;
    object-fit: cover;
  }
  &_right {
    width: 100%;
    margin: 0 0 0 30px;
  }
  &_ttl {
    margin: 0 0 30px 0;
  }
  &_info {
    display: flex;
    align-items: center;
    position: relative;
    &:before {
      display: block;
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 28px;
      height: 28px;
      border-radius: 9999px;
      background: $bluegreen_1;
    }
    &:after {
      display: block;
      content: "";
      position: absolute;
      right: 15px;
      bottom: 8px;
      width: 10px;
      height: 10px;
      margin: 0 -8px 0 11px;
      border-left: solid 8px #fff;
      border-top: solid 6px transparent;
      border-bottom: solid 6px transparent;
    }
  }
  &_cat {
    margin: 0 15px 0 0;
    padding: 3px 10px 4px 10px;
    border-radius: 9999px;
    font-weight: 300;
    font-size: 16px;
    color: $white;
    background: $bluegreen_1;
  }
  &_date {
    padding: 0 0 0 15px;
    border-left: solid 1px $gray_4;
    font-size: 16px;
  }
  @include pc_tab {
    &:hover {
      opacity: 0.6;
    }
  }

  @include sp {
    flex-direction: column;
    padding: 15px 15px 20px 15px;
    border-radius: 10px;
    &_left {
      width: 100%;
      border-radius: 10px;
    }
    &_right {
      margin: 20px 0 0 0;
    }
    &_ttl {
      margin: 0 0 20px 0;
    }
    &_info {
      &:before {
        bottom: -3px;
      }
      &:after {
        bottom: 5px;
      }
    }
    &_cat {
      font-size: 14px;
    }
  }
}

.c_card_7 {
  display: block;
  max-width: 315px;
  min-height: 440px;
  border-radius: 20px;
  border: solid 1px $gray_4;
  box-shadow: 2px 3px 1px 0px rgba(0, 0, 0, 0.082);
  overflow: hidden;
  transition: 0.3s;
  @include pc_tab {
    &:hover {
      .c_card_7_img {
        opacity: 0.6;
      }
    }
  }
  @include sp {
    border-radius: 10px;
  }
  &_bg {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 160px;
    background: $yellow_1;
  }
  &_img {
    display: block;
    max-width: 120px;
    max-height: 142px;
    object-fit: contain;
    transition: 0.3s;
  }
  &_ttl_box {
    padding: 15px 15px 0 15px;
  }
  &_ttl {
    margin: 0 0 15px 0;
    line-height: 34px;
    font-size: 26px;
    font-weight: 700;
  }
  &_tag {
    display: flex;
    flex-wrap: wrap;
    margin: 7px -2px 12px -2px;
    & > * {
      margin: 2px;
    }
  }
  &_txt_box {
    padding: 0 15px 28px 15px;
  }
  @include sp {
    max-width: unset;
    min-height: unset;
    &_head {
      display: flex;
    }
    &_bg {
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 135px;
    }
    &_img {
      width: 67px;
      height: 80px;
    }
    &_ttl {
      margin: 0 0 10px 0;
      font-size: 20px;
      line-height: 30px;
    }
    &_tag {
      margin: 3px -2px;
    }
    &_txt_box {
      padding: 15px;
    }
  }
}

/* =================================
  hamburger
================================= */
.c_ham {
  display: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 80px 0 85px 0;
  overflow-y: auto;
  background: #fffffff4;
  text-align: center;
  &._is_open {
    right: 0;
  }

  // text
  &_txt {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-weight: 700;
  }
  &_cv_btn {
    max-width: 305px;
    &:nth-of-type(1) {
      margin: 14px auto 0 auto;
    }
    &:nth-of-type(2) {
      margin: 20px auto 0 auto;
    }
    &:nth-of-type(3) {
      margin: 20px auto 0 auto;
    }
  }
  &_time {
    display: flex;
    justify-content: center;
    margin: 15px 0 0 0;
    font-family: $font_2;
    font-weight: 400;
    font-size: 14px;
  }
  &_dl {
    display: grid;
    grid-template-columns: 50px 1fr;
    margin: 6px 0 0 8px;
    text-align: left;
  }

  // navigation
  &_nav {
    margin: 10px auto 0 auto;
  }
  &_nav_item {
    position: relative;
    display: flex;
    align-items: center;
    height: 62px;
    padding: 0 30px;
    font-size: 16px;
    transition: 0.3s;
    &:before {
      content: "";
      width: 10px;
      margin: 0 3px 0 0;
      border-left: solid 8px $bluegreen_1;
      border-top: solid 6px transparent;
      border-bottom: solid 6px transparent;
      transition: 0.3s;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 15px;
      width: calc(100% - 30px);
      height: 2px;
      background-image: url(#{$img_dir}/common/dot.svg);
      background-position: 0 100%;
      background-repeat: repeat no-repeat;
      background-size: auto 2px;
    }
    @include pc_tab {
      &:hover {
        opacity: 0.6;
      }
    }
  }
  &_nav_list li:nth-last-child(1) .c_ham_nav_item:after {
    content: none;
  }

  // hamburger btn
  &_btn {
    position: fixed;
    z-index: 100;
    top: 5px;
    right: 5px;
    height: 60px;
    width: 60px;
    border-radius: 9999px;
    box-shadow: 0px 3px 6px 0px #00000044;
    background: $white;
    cursor: pointer;
    transition: 0.3s;
    @include pc_tab {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &_btn_open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &_btn_open_item {
    display: block;
    content: "";
    height: 2px;
    width: 20px;
    border-radius: 9999px;
    background: $bluegreen_1;
    transition: opacity 0.3s ease;
    & + & {
      margin-top: 5px;
    }
  }
  &_btn_close {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &_btn_close_item {
    display: block;
    content: "";
    height: 2px;
    width: 20px;
    margin: -2px;
    border-radius: 9999px;
    background: $bluegreen_1;
    transition: opacity 0.3s ease;
    &:nth-child(1) {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }

  @include pc {
    display: none;
    &_btn {
      display: none;
    }
  }
}

/* =================================
  section 
================================= */

.c_section {
  margin-top: 85px;
  &_lead {
    margin: 25px 0 0 0;
    font-size: 18px;
    text-align: center;
  }
  &_content {
    margin: 25px 0 0 0;
  }
  &_bottom {
    display: flex;
    justify-content: center;
    margin: 40px 0 0 0;
  }
  @include sp {
    margin: 60px 0 0 0;
    &_lead {
      margin: 22px 0 0 0;
      text-align: left;
    }
    &_content {
      margin: 20px 0 0 0;
    }
    &_bottom {
      margin: 20px 0 0 0;
    }
    &._mt_sm {
      margin: 30px 0 0 0;
    }
  }
}

/* =================================
  cta
================================= */

.c_cta {
  width: 100%;
  padding: 40px;
  border-radius: 20px;
  background-image: linear-gradient(-45deg, $yellow_1 calc(50% - 1px), $white calc(50% - 1px), $white 50%, $yellow_1 calc(50%));
  background-size: 14px 14px;
  background-repeat: repeat;
  &_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    background: $white;
    gap: 0 40px;
  }
  &_left {
    width: 43%;
    max-width: 303px;
    border-radius: 15px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include pc_tab {
        min-height: 230px;
      }
    }
  }
  &_right {
    margin: 0 auto;
    padding: 0 30px 0 0;
    flex: 1 1;
  }
  &_ttl {
    margin: 5px 0 20px 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }
  &_btnbox {
    display: flex;
    flex-wrap: wrap;
    gap: 13px 20px;
  }
  &_btn {
    margin: 13px auto 0 auto;
    flex: 1 1;
    font-size: 20px;
    position: relative;
  }
  &_btn_sup {
    font-size: 12px;
    line-height: 23px;
    padding: 0 14px;
    border-radius: 11px;
    background: $yellow_1;
    font-weight: bold;
    color: $black;
    position: absolute;
    top: -11px;
    pointer-events: none;
    z-index: 1;
  }
  @include sp {
    padding: 20px 15px;
    border-radius: 0;
    &_inner {
      flex-direction: column;
      padding: 10px 10px 23px 10px;
      border-radius: 10px;
    }
    &_left {
      width: 100%;
      max-width: 100%;
      border-radius: 5px;
    }
    &_right {
      padding: 0 10px;
      margin: 20px 0 0;
      width: 100%;
    }
    &_ttl {
      font-size: 25px;
    }
    &_btn {
      width: 100%;
      flex: 0 1 auto;
    }
  }
}

/* =================================
  box
================================= */
.c_box_bg {
  padding: 30px 20px;
  border-radius: 20px;
  background: $gray_2;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }

  @include sp {
    padding: 20px 15px;
  }
}

.c_box_border {
  padding: 30px 20px;
  border-radius: 20px;
  border: solid 1px $gray_4;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }

  @include sp {
    padding: 20px 15px;
  }
}

.c_box_quote {
  $border_size: 6px;

  position: relative;
  padding: 50px 40px;
  border-radius: $border_size;
  border: solid $border_size $bluegreen_4;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  &:before {
    content: "";
    position: absolute;
    top: -#{$border_size};
    left: 10px;
    width: 32px;
    height: 28px;
    background: url(#{$img_dir}/common/quote.svg) no-repeat;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -#{$border_size};
    right: 10px;
    width: 32px;
    height: 28px;
    background: url(#{$img_dir}/common/quote.svg) no-repeat;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg);
  }

  @include sp {
    padding: 20px 10px;
    &:before,
    &:after {
      width: 22px;
      height: 19px;
    }
  }
}

.c_box_figure {
  width: 100%;
  padding: 40px;
  border-radius: 6px;
  background: $gray_3;
  &_media {
    width: 100%;
    margin: 0 0 30px 0;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
  }
  &_media_item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include sp {
    padding: 15px;
    &_media {
      margin: 0 0 20px 0;
    }
  }
}

.c_full_img {
  width: 100%;
  object-fit: contain;
  border-radius: 20px;

  @include sp {
    border-radius: 10px;
  }
}

/* =================================
  pager
================================= */
.c_pager_num {
  display: flex;
  justify-content: center;
  font-family: $font_2;
  font-size: 16px;
  font-weight: 700;
  .page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border: solid 1px $gray_6;
    border-radius: 9999px;
    transition: 0.3s;
    & + .page-numbers {
      margin: 0 0 0 5px;
    }
    @include pc_tab {
      &:link:hover {
        opacity: 0.6;
      }
    }
  }
  .current {
    border: none;
    background: $bluegreen_1;
    color: $white;
  }
  .dots {
    width: 25px;
    height: 35px;
    border: none;
  }
  .first,
  .prev,
  .next,
  .last {
    position: relative;
    border: none;
    background: $yellow_1;
    color: transparent;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: 55% 50%;
    }
  }
  .prev,
  .next {
    &:after {
      background-image: url(#{$img_dir}/common/arrow_1.png);
      background-size: 8px 14.8px;
    }
  }
  .prev {
    transform: rotate(180deg);
  }
  .first,
  .last {
    &:after {
      background-image: url(#{$img_dir}/common/arrow_2.png);
      background-size: 14px 14.8px;
    }
  }
  .first {
    transform: rotate(180deg);
  }

  @include sp {
    .page-numbers {
      flex-shrink: 0;
      width: 35px;
      height: 35px;
      font-size: 12px;
      & + .page-numbers {
        margin: 0 0 0 4px;
      }
    }
    .prev,
    .next {
      &:after {
        background-size: 7.5px 13px;
      }
    }
    .first,
    .last {
      &:after {
        background-size: 12.5px 13px;
      }
    }
  }
}

/* =================================
  合宿プラン検索
================================= */
.c_form_item + .c_form_item {
  margin: 40px 0 0;
} 

.c_flex {
  display: flex;
  align-items: end;
}
.c_input_area {
  &._pt01 {
    margin: 5px 0 0;
  }
  &._pt02 {
    margin: 27px 0 0;
  }
}

.c_item_head {
  display: flex;
  align-items: baseline;
}

.c_item_require {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 22px;
  border-radius: 2px;
  background: $color_4;
  margin: 0 20px 0 0;
}

.c_txt_error {
  position: relative;
  display: none;
  padding: 4px 15px 6px 5px;
  margin: 15px 0 0;
  background: $color_7;
  .txt {
    display: block;
    padding: 0 0 0 1em;
    text-indent: -1em;
  }
  &._is_open {
    display: inline-block;
  }
}

.form_step {
  margin: 25px 0 0;
}

//チェックボックス初期化
input[type="checkbox"] {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

// チェックボックス
.c_wrap_checkbox {
  display: inline-flex;
  align-items: center;
  &._mb {
    display: flex;
    margin: 0 0 30px;
  }
  &._mt {
    margin-top: 20px;
  }
  &._mt_sp {
    @include sp {
      margin-top: 15px;
    }
  }
  &._ml {
    margin-left:20px;
    @include sp {
      margin-left: 0;
    }
  }
  .c_checkbox {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1px solid $color_5;
    background: $white;
    border-radius: 4px;
    &:checked {
      border: 1px solid $bluegreen_1;
      background: $bluegreen_1;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 11px;
        height: 8px;
        background-image: url(#{$img_dir}/common/icon-check.svg);
        background-size: cover;
      }
    }
  }
  .c_checkbox_txt {
    display: block;
    margin: 0 0 0 10px;
    padding: 0 0 1px;
  }
}

//プルダウン
.c_pulldown_wrap {
  display: flex;
  align-items: center;
  .c_pulldown {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    border: 1px solid $color_1;
    border-radius: 5px;
    &._pt1 {
      width: 112px;
      @include sp {
        width: 90px;
      }
    }
    &._pt2 {
      width: 90px;
      @include sp {
        width: 60px;
      }
    }
    &::after {
      content: '▼';
      position: absolute;
      right: 20px;
      font-size: 16px;
      @include sp {
        right: 9px;
        font-size: 14px;
      }
    }
    select {
      cursor: pointer;
      padding: 14px 20px 14px;
      @include sp {
        padding: 14px 12px 14px;
        
      }
    }
  }
  .c_pulldown_txt {
    display: block;
    margin: 0 0 0 6px;
  }
}
.c_pulldown_wrap + .c_pulldown_wrap {
  margin: 0 0 0 20px;
}

//ラジオボタン
input[type="radio"] {
  margin: 0;
  padding: 0;
  outline: none;
  appearance: none;
}
.c_radio_wrap {
  display: flex;
  align-items: center;
}
.c_radio_btn {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $white;
  border: 1px solid $color_8;
  &:checked {
    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 9px;
      height: 9px;
      background: $bluegreen_1;
      border-radius: 50%;
      @include sp {
      }
    }
  }
}
.c_radio_txt {
  display: block;
  margin: 0 0 0 10px;
}
.c_radio_wrap + .c_radio_wrap {
  margin: 0 0 0 30px;
}