/* ======================================================================
 utility
====================================================================== */

/* =================================
 layout
================================= */
// clearfix
.u_cf {
  @include cf;
}

// pc・sp 出し分け（media-queryはサイトの仕様に合わせて変更すること）
@include pc_tab {
  .u_pc {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
  .u_sp {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
}

@include sp {
  .u_pc {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
  .u_sp {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
}

/* =================================
 content_size
================================= */
.u_content_size {
  padding: 0 $sp_side_padding;
  @include pc_tab {
    padding: 0;
    margin: 0 auto;
    width: $content_width;
  }
}

.u_conainer_lg {
  width: 100%;
  max-width: 1145px;
  margin: 0 auto;
  padding: 0 40px;
  @include tab {
    padding: 0 35px;
  }
  @include sp {
    padding: 0;
  }
}

/* =================================
 text
================================= */
.u_txt_1 {
  font-size: 36px;
  @include sp {
    font-size: 30px;
  }
}

.u_txt_2 {
  font-size: 16px;
  line-height: 1.7;
}

.u_txt_3 {
  font-size: 18px;
  line-height: 30px;
}

/* =================================
 text weight
================================= */
.u_lighter {
  font-weight: lighter;
}

.u_bold {
  font-weight: bold;
}

.u_sp_bold {
  @include sp {
    font-weight: 700;
  }
}

/* =================================
 text align
================================= */
.u_ta_c {
  text-align: center;
}

.u_ta_r {
  text-align: right;
}

.u_ta_cl {
  @include sp {
    text-align: center;
  }
}

.u_ta_lc {
  @include pc_tab {
    text-align: center;
  }
}

.u_ta_cr {
  @include sp {
    text-align: center;
  }
  @include pc_tab {
    text-align: right;
  }
}

.u_ta_rc {
  @include sp {
    text-align: right;
  }
  @include pc_tab {
    text-align: center;
  }
}

/* =================================
 paragraph margin
================================= */
.u_paragraph {
  font-size: 18px;
  line-height: 1.7;
  & + & {
    margin: 12px 0 0;
  }
}

/* =================================
 hover animation
================================= */
.u_hover_1 {
  transition: opacity 0.3s;
  @include pc {
    &:hover {
      opacity: 0.7;
    }
  }
}

/* =================================
 fonts
================================= */
.u_txt_bluegreen {
  color: $bluegreen_1;
  transition: 0.3s;
}

.u_txt_pink {
  color: $pink_1;
  transition: 0.3s;
}

.u_txt_yellow {
  color: $yellow_1;
  transition: 0.3s;
}

.u_txt_red {
  color: $red_1;
  transition: 0.3s;
}

.u_txt_red_2 {
  color: $color_4;
  transition: 0.3s;
}

.u_txt_orange {
  color: $orange_1;
  transition: 0.3s;
}

.u_txt_orange_2 {
  color: $color_6;
  transition: 0.3s;
}

.u_txt_white {
  color: $white;
  transition: 0.3s;
}

.u_txt_underline {
  text-decoration: underline;
}

.u_ff_raboto {
  font-family: $font_2;
}

.u_fs_1 {
  font-size: 14px;
}
.u_fs_2 {
  font-size: 16px;
}
.u_fs_3 {
  font-size: 18px;
}
.u_fs_4 {
  font-size: 20px;
}
.u_fs_5 {
  font-size: 22px;
}
.u_fs_6 {
  font-size: 24px;
}
.u_fs_7 {
  font-size: 26px;
}
.u_fs_8 {
  font-size: 28px;
}
.u_fs_9 {
  font-size: 30px;
}
.u_fs_10 {
  font-size: 34px;
}
.u_fs_11 {
  font-size: 36px;
}
.u_fs_12 {
  font-size: 40px;
}
.u_fs_13 {
  font-size: 12px;
}

.u_sp_fs_1 {
  @include sp {
    font-size: 14px;
  }
}
.u_sp_fs_2 {
  @include sp {
    font-size: 16px;
  }
}
.u_sp_fs_3 {
  @include sp {
    font-size: 18px;
  }
}
.u_sp_fs_4 {
  @include sp {
    font-size: 20px;
  }
}
.u_sp_fs_5 {
  @include sp {
    font-size: 22px;
  }
}
.u_sp_fs_6 {
  @include sp {
    font-size: 24px;
  }
}
.u_sp_fs_7 {
  @include sp {
    font-size: 26px;
  }
}
.u_sp_fs_8 {
  @include sp {
    font-size: 28px;
  }
}
.u_sp_fs_9 {
  @include sp {
    font-size: 30px;
  }
}
.u_sp_fs_10 {
  @include sp {
    font-size: 34px;
  }
}
.u_sp_fs_11 {
  @include sp {
    font-size: 36px;
  }
}
.u_sp_fs_12 {
  @include sp {
    font-size: 40px;
  }
}
.u_sp_fs_13 {
  @include sp {
    font-size: 12px;
  }
}
.u_sp_fs_14 {
  @include sp {
    font-size: 11px;
  }
}
.u_sp_fs_15 {
  @include sp {
    font-size: 8px;
  }
}

.u_fw_300 {
  font-weight: 300;
}
.u_fw_500 {
  font-weight: 500;
}
.u_fw_700 {
  font-weight: 700;
}
.u_fw_900 {
  font-weight: 900;
}

.u_bg_yellow_1 {
  background-image: url(#{$img_dir}/common/t_border.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position-y: 100%;
}

.u_bg_yellow_2 {
  background-image: url(#{$img_dir}/common/t_border-1.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position-y: 100%;
}

.u_bg_circle {
  margin: -20px 4px -20px -6px;
  padding: 19.5px;
  background-image: url(#{$img_dir}/common/bg-1.svg);
  background-repeat: no-repeat;
  background-size: calc(100% - 13px);
  background-position: 7px 4px;
  color: $yellow_1;
  font-weight: 700;
  @include sp {
    margin: -16px 0px;
    padding: 16px;
    background-size: calc(100% - 10px);
    background-position: 4.5px 1px;
  }
}

.u_box_shadow {
  box-shadow: 2px 3px 1px 0px rgba(0, 0, 0, 0.082);
}

.u_mx_auto {
  margin-left: auto;
  margin-right: auto;
}