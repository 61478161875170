/* ======================================================================
 layout
====================================================================== */
/* =================================
 l_container
================================= */
.l_container {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 220px calc(100% - 220px);
  @include tab {
    display: block;
  }
  @include sp {
    display: block;
  }
}
/* =================================
 l_header
================================= */
.l_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  width: 220px;
  height: 100vh;
  padding: 48px 0;
  border-right: solid 1px $gray_1;
  background: $gray_2;
  @include pc {
    overflow: auto;
  }

  &_logo {
    display: block;
    width: 163px;
    height: 187px;
    transition: 0.3s;
    @include pc_tab {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &_nav {
    width: 100%;
    padding: 30px 0;
    margin: 30px 0;
    border-top: solid 1px $gray_1;
    border-bottom: solid 1px $gray_1;
  }

  &_list {
    width: 135px;
    margin: 0 auto;
  }

  &_list-item {
    font-size: 16px;
    font-weight: 700;
    transition: 0.3s;

    & + & {
      margin: 20.5px 0 0;
    }
    @include pc_tab {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &_cv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_tel {
    display: block;
    transition: 0.3s;
    @include pc_tab {
      &:hover {
        opacity: 0.6;
      }
    }

    img {
      width: 180px;
      height: 20px;
    }
  }

  &_time {
    display: flex;
    margin: 15px 0 0;
    font-family: $font_2;
    font-size: 14px;
  }

  &_dl {
    display: grid;
    grid-template-columns: 50px 1fr;
    margin: 4px 0 0 8px;
  }

  &_btn {
    width: 200px;
    margin: 20px 0 0;
  }
  @include tab {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 130px;
    height: 130px;
    padding: 13px 0 0;
    border-radius: 0 0 26px 0;
    border: none;
    background: $white;

    & > *:not(.l_header_logo) {
      display: none;
    }

    &_logo {
      width: 130px;
      height: 130px;
      text-align: center;

      img {
        width: 100px;
        object-fit: contain;
      }
    }
  }
  @include sp {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 130px;
    height: 130px;
    padding: 13px 0 0;
    border-radius: 0 0 26px 0;
    border: none;
    background: $white;

    & > *:not(.l_header_logo) {
      display: none;
    }

    &_logo {
      width: 130px;
      height: 130px;
      text-align: center;

      img {
        width: 100px;
        object-fit: contain;
      }
    }
  }
}
@include tab {
  ._horizontal {
    &.l_header {
      align-items: flex-start;
      justify-content: center;
      padding: 0 15px;
      width: 100%;
      height: 74px;
      box-shadow: 0 1px 3px #0000000d;
      border-radius: 0;
    }

    .l_header {
      &_logo {
        width: auto;
        height: auto;

        img {
          width: 167px;
        }
      }
    }
  }
}
@include sp {
  ._horizontal {
    &.l_header {
      align-items: flex-start;
      justify-content: center;
      padding: 0 15px;
      width: 100%;
      height: 74px;
      box-shadow: 0 1px 3px #0000000d;
      border-radius: 0;
    }

    .l_header {
      &_logo {
        width: auto;
        height: auto;

        img {
          width: 167px;
        }
      }
    }
  }
}
/* =================================
 l_breadcrumbs
================================= */
.l_breadcrumbs {
  padding: 0 40px;
  @include pc_tab {
    position: absolute;
    top: 360px;
    width: 100%;
  }
  @include tab {
    top: 434px;
  }
  @include sp {
    width: 100%;
    padding: 74px 0 0;
    overflow-x: scroll;
  }

  .breadcrumbs {
    padding: 13px 20px;
    border-radius: 26px;
    background: $gray_2;
    font-size: 16px;
    @include sp {
      padding: 10px 15px;
      border-radius: 0;
      white-space: nowrap;
    }
    @include pc_tab {
      max-width: 1140px;
      margin: 0 auto;
    }
  }

  .breadcrumb_last {
    @include sp {
      padding: 0 15px 0 0;
    }
  }

  .delimiter {
    position: relative;
    top: -3px;
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0 5px;
    border-top: 1px solid #242424;
    border-right: 1px solid #242424;
    transform: rotate(45deg);
  }

  a {
    color: $bluegreen_1;
  }
}
/* =================================
 l_main_visual
================================= */
.l_main_visual {
  padding: 40px 40px 20px;
  @include pc_tab {
    margin-bottom: 125px;
  }
  @include tab {
    margin-top: 74px;
  }
  @include sp {
    padding: 15px;
  }
  &._search {
    @include pc_tab {
      margin-bottom: 0;
    }

  }

  .box {
    position: relative;
    height: 300px;
    border-radius: 20px;
    @include sp {
      height: 180px;
      border-radius: 10px;
    }
    @include pc_tab {
      max-width: 1140px;
      margin: 0 auto;
    }
  }

  .title {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px 9px;
    border-radius: 999px;
    background: $white;
    opacity: 0.95;
    @include pc_tab {
      min-width: 500px;
    }
    @include sp {
      bottom: -30px;
      width: 94.2%;
      padding: 14px 0 15px;
    }
  }

  .cat {
    min-width: 140px;
    height: 35px;
    padding: 0 20px;
    line-height: 33px;
    background: $bluegreen_1;
    border-radius: 23px;
    white-space: nowrap;
    color: $white;
    font-size: 18px;
    @include pc_tab {
      margin: 0 15px 0 0;
    }
    @include sp {
      position: absolute;
      top: -22px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 180px;
    }
  }

  .heading {
    font-size: 40px;
    @include pc_tab {
      white-space: nowrap;
    }
    @include sp {
      font-size: 30px;
    }
  }
}
/* =================================
 l_main_visual
================================= */
.l_main_visual_top {}
/* =================================
 l_content
================================= */
.l_content {
  overflow-x: hidden;
}
/* =================================
 l_main
================================= */
.l_main {
  $padding_x: 60px;
  width: 100%;
  max-width: calc(#{$max_content_width} + #{$padding_x} * 2);
  margin: 0 auto;
  padding: 0 $padding_x;
  @include tab {
    padding: 0 35px;
  }
  @include sp {
    padding: 0 15px;
  }
  &._search {
    position: relative;
    margin: -260px auto 0;
    padding: 0;
    width: 1065px;
    background: #fff;
    border-radius: 30px;
    @include sp {
      margin: -193px auto 0;
      width: 100%;
      border-radius: 0;
      
    }
  }
}
/* =================================
 l_footer
================================= */
.l_footer {
  margin: 80px 0 0;

  .inner_border {
    @include pc_tab {
      margin: 80px 0 0;
      border-top: solid 2px $gray_3;
    }
  }

  &_inner {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    max-width: 1120px;
    margin: 0 auto;
    padding: 50px 50px 50px 46px;
  }

  &_left {
    display: flex;
    flex-grow: 1;
    width: 50%;
    min-width: 550px;
  }

  &_logo {
    margin: 0 30px 0 0;
    transition: 0.3s;
    @include pc_tab {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &_dl {
    margin: -5px 0 0;
    font-size: 18px;
    line-height: 167%;
  }

  &_dt {
    position: relative;
    font-weight: 700;
  }
  &_privacy {
    position: absolute;
    top: 0;
    right: 60px;
    width: 62px;
    height: 62px;
    object-fit: contain;
  }
  &_dx {
    position: absolute;
    top: 0px;
    right: -10px;
    width: 62px;
    height: 62px;
    object-fit: contain;
  }

  &_sns {
    display: flex;
    margin: 25px -10px -10px;
  }

  &_sns_item {
    margin: 10px;

    img {
      height: 40px;
      transition: 0.3s;
    }
    @include pc_tab {
      &:hover {
        img {
          opacity: 0.6;
        }
      }
    }
  }

  &_sns_link {
    display: block;
  }

  &_right {
    width: 36%;
  }

  &_nav {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    margin: -11px -15px -6px auto;
  }

  &_nav_item {
    margin: 11px 15px;
  }

  &_nav_link {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;

    &:before {
      content: "";
      width: 10px;
      margin: 0 3px 0 0;
      border-left: solid 8px #00a29a;
      border-top: solid 6px transparent;
      border-bottom: solid 6px transparent;
    }
    @include pc_tab {
      &:hover {
        color: $bluegreen_1;
        text-decoration: underline;
        text-underline-position: under;
      }
    }
  }

  &_bnr {
    display: block;
    width: fit-content;
    margin: 35px 0 0 auto;
    transition: 0.3s;
    @include pc_tab {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &_bnr_img {
    width: 273px;
    max-width: 100%;
    height: 70.5px;
    object-fit: contain;
  }

  &_copyright {
    display: block;
    width: 100%;
    padding: 18px 0;
    font-size: 16px;
    text-align: center;
    background: $gray_3;
  }
  @include sp {
    margin: 60px 0 0;
    border: none;

    &_inner {
      flex-direction: column-reverse;
      margin: 60px 0 0;
      padding: 40px 40px 30px;
      background: $gray_2;
    }

    &_left {
      flex-direction: column;
      width: 100%;
      min-width: 100%;
    }

    &_logo {
      margin: 40px auto 25px;
    }

    &_dl {
      font-size: 16px;
    }

    &_dd {
      &._marks {
        display: flex;
        margin: 10px 0;
      }
    }

    &_privacy, 
    &_dx {
      position: static;
    }

    &_dx {
      margin: 0 0 0 10px;
    }

    &_sns {
      justify-content: center;
      margin: 15px -10px -10px;
    }

    &_right {
      width: 100%;
    }

    &_nav_item {
      display: flex;
      align-items: center;
      justify-content: left;
      width: 50%;
      margin: 0;
      padding: 15px 0;
    }

    &_nav_link {
      align-items: baseline;
      line-height: 26px;
      font-size: 16px;
    }

    &_bnr {
      margin: 27px auto 0;
    }
  }
  @include mq_max(360) {
    &_privacy {
      right: -20px;
      width: 52px;
      height: 52px;
    }
    &_dx {
      right: 0;
      width: 52px;
      height: 52px;
    }
  }
}